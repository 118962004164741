<template>
    <div class="sidebar-page">
    <section class="sidebar-layout">
        <GuildSidebar :beta="guild ? (guild.guildDB.data ? (guild.guildDB.data.beta === 1 ? true : false) : false ) : undefined" :showAds="showAds" :current="page" :premium="guild ? (guild.guildDB.data ? (guild.guildDB.data.premium === 1 ? true : false) : false ) : undefined" :loaded="loaded" :id="guild ? guild.id : this.$route.params.id" :name="guild ? guild.name : null" :icon="guild ? guild.icon : null" />
        <div class="p-1" style="">
          <GuildTopInfoCards :loaded="loaded" :approximate_member_count="guild ? guild.bot.data.approximate_member_count : undefined" :max_members="guild ? guild.bot.data.max_members : undefined" :access_status="guild ? (guild.owner ? 'Owner' : (guild.isAdmin ? 'Admin' : (guild.isManager ? 'Manager' : undefined))) : undefined" :role_count="guild ? guild.bot.data.roles.length : undefined" :channel_count="guild ? guild.bot.data.channels.filter(g => Number(g.type) !== 4).length : undefined" />
          <b-notification style="margin-left: 2rem; margin-right: 2rem;margin-top: -20px;" :active="someDisabledElements" type="is-warning" aria-close-label="Close notification" role="alert">
            Some options might be disabled as you do not meet the requirements or a system queue has disabled it. Check Your Permissions, Settings, Premium Status.
          </b-notification>
          <keep-alive>
            <component id="GuildPages" v-if="loaded" :is="page.toUpperCase()" :guildData="guild ? guild : null" :loaded="loaded" />
          </keep-alive>
            <!-- <div class="container" style="width:50%">
              <div class="columns is-mobile is-centered is-multiline">
                <b-tooltip :v-if="Boolean(showAds && loaded)" type="is-info">
                  <template v-slot:content><b>Why do we show ads?</b>. <br>The simple answer is to make some money to keep us going.<br>We made sure to place them in a way so your expirence isnt damaged.<br>If you dont want to see ads support us by getting premium!</template>
                  <Adsense v-if="Boolean(showAds && loaded) && this.$window767.matches" style="text-align: center;" ins-style="display:block;margin-top: 25px;height:250px;width:250px;text-align: center;" :data-ad-client="this.$store.getters.google.adClient" :data-ad-slot="this.$store.getters.google.dashboardSelector"></Adsense>
                </b-tooltip>
              </div>
            </div> -->
        </div>
    </section>
</div>
</template>
<script>
// @ is an alias to /src
  import 'vue-select/dist/vue-select.css'
  import GuildSidebar from '@/components/GuildSidebar.vue'
  import GuildTopInfoCards from '@/components/GuildTopInfoCards.vue'
  import MAIN from './guild/main'
  import MOD from './guild/mod'
  import LOG from './guild/log'
  import LEVEL from './guild/level'
  import TWITCH from './guild/twitch'
  import ACTIONS from './guild/actions'
  import MUSIC from './guild/music'
  import CUSTOMIZE from './guild/customize'
  import AUDIT from './guild/audit'
  import REDDIT from './guild/reddit'

  export default {
    name: 'dashboard',
    components: {
      GuildSidebar,
      GuildTopInfoCards,
      // GuildPages,
      MAIN,
      MOD,
      LOG,
      LEVEL,
      TWITCH,
      ACTIONS,
      MUSIC,
      CUSTOMIZE,
      AUDIT,
      REDDIT
    },
    data () {
      return {
        loaded: false,
        guild: null,
        page: '',
        someDisabledElements: false,
        showAds: true
      }
    },
    watch: {
      async '$route.params.page' (to) {
        this.page = to
        await this.$nextTick()
        this.getGuild()
        await this.$store.dispatch('sleep', 1000)
        console.log(this.$('#GuildPages').find('.is-disabled'))
        this.someDisabledElements = (this.$('#GuildPages').find('.is-disabled').length > 0)
        await this.$nextTick()
      },
      async 'loaded' (load) {
        if (!load) return
        await this.$store.dispatch('sleep', 1000)
        this.someDisabledElements = (this.$('#GuildPages').find('.is-disabled').length > 0)
        await this.$nextTick()
      }
    },
    async created () {
      this.page = this.$route.params.page
      await this.$nextTick()
      this.getGuild()
    },
    methods: {
      getGuild (showmsg = false) {
        if (showmsg === true) {
          this.$buefy.snackbar.open({
            message: 'Reloading guild data!',
            type: 'is-warning',
            duration: 15000
          })
        }
        this.$store.dispatch('getGuild', { id: this.$route.params.id }).then(async (result) => {
          if (!result.data.data.isManager) return this.$router.push('/dashboard')
          this.guild = result.data.data
          if (this.guild.guildDB.data.banned) return this.$router.push('/dashboard?from_guild_id=' + this.$route.params.id + '&banned=true')
          // this.guild.guildDB.data.welcomeBackground = this.defaultwelcomeImages.find(g => g.fileName === this.guild.guildDB.data.welcomeBackground)
          this.guild.guildDB.data.color = ('#' + this.guild.guildDB.data.color)
          if (isJSON(this.guild.guildDB.data.swearData.words)) {
            this.guild.guildDB.data.swearData.words = JSON.parse(this.guild.guildDB.data.swearData.words)
          } else {
            this.guild.guildDB.data.swearData.words = JSON.parse(JSON.stringify(this.guild.guildDB.data.swearData.words))
          }
          if (isJSON(this.guild.guildDB.data.swearBypassRole)) {
            this.guild.guildDB.data.swearBypassRole = JSON.parse(this.guild.guildDB.data.swearBypassRole)
          } else {
            this.guild.guildDB.data.swearBypassRole = JSON.parse(JSON.stringify(this.guild.guildDB.data.swearBypassRole))
          }
          if (isJSON(this.guild.guildDB.data.welcomeAutoRole)) {
            this.guild.guildDB.data.welcomeAutoRole = JSON.parse(this.guild.guildDB.data.welcomeAutoRole)
          } else {
            this.guild.guildDB.data.welcomeAutoRole = JSON.parse(JSON.stringify(this.guild.guildDB.data.welcomeAutoRole))
          }
          if (isJSON(this.guild.guildDB.data.protectlinkRole)) {
            this.guild.guildDB.data.protectlinkRole = JSON.parse(this.guild.guildDB.data.protectlinkRole)
          } else {
            this.guild.guildDB.data.protectlinkRole = JSON.parse(JSON.stringify(this.guild.guildDB.data.protectlinkRole))
          }
          if (isJSON(this.guild.guildDB.data.levelRoleUp)) {
            this.guild.guildDB.data.levelRoleUp = JSON.parse(this.guild.guildDB.data.levelRoleUp)
          } else {
            this.guild.guildDB.data.levelRoleUp = JSON.parse(JSON.stringify(this.guild.guildDB.data.levelRoleUp))
          }
          this.showAds = Number(this.guild.guildDB.data.premium) <= 0
          if (this.showAds) this.showAds = (this.$store.getters.user ? (this.$store.getters.user.premium <= 0) : true)
          if (!this.$store.getters.google.showAds) this.showAds = false
          this.loaded = true
          function isJSON (str) {
            try {
              return (JSON.parse(str) && !!str)
            } catch (e) {
              return false
            }
          }
        }).catch(async (error) => {
          console.log(error, JSON.stringify(error), error.response, 'e')
          if (Number(error.response.status) === 409 || Number(error.response.data.code) === 409) return this.$router.push('/invite?guild_id=' + this.$route.params.id)
          return this.$router.push('/dashboard')
        })
      }
    }
  }
</script>
<style>
  .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__clear, .vs--disabled .vs__search, .vs--disabled .vs__selected, .vs--disabled .vs__open-indicator {
    background: var(--main-color-bg-2);
    cursor: not-allowed;
    color: #7a7a7a !important;
  }
  .vs__dropdown-toggle {
    background: var(--main-color-bg-2);
    color: white !important;
    border-color: var(--main-color-bg-3) !important;
    min-height: 2.25em !important;
    height: 100% !important;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .vs__dropdown-menu {
    background: var(--main-color-bg-1);
    color: white;
    border-color: var(--main-color-bg-3) !important;
    border-top-style: unset !important;
    border-radius: 4px 4px 4px 4px !important;
  }
  .vs__open-indicator {
    fill: #6045aa !important;
  }
  .vs__open-indicator:hover {
    fill: #7957d5 !important;
  }
  .vs__clear {
    fill: darkred !important;
  }
  .vs__clear:hover {
    fill: red !important;
  }
  .vs--single .vs__selected {
    color: white;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
  .vs__dropdown-option--selected {
    background-color: var(--main-color-bg-2);
  }
  .vs__dropdown-option--highlight {
    background: var(--main-color-bg-3) !important;
  }
  .vs_actions {
    cursor: pointer;
  }
  .vs__selected {
    background-color: var(--main-color-bg-1);
    color: white;
  }
  .vs__deselect {
    fill: darkred;
  }
  .vs__deselect:hover {
    fill: red;
  }
</style>

<style>
  .labelcolor-warning > .label {
    color: #ffdd57 !important
  }
  .help {
    color: white !important;
  }
  .hovermessagefeild:hover > .help {
    display: unset !important;
  }
  .hovermessagefeild > .help {
    display: none !important;
  }
  .is-invisible {
    display: none !important;
  }
  .has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }
</style>

<style lang="scss" scoped>
.button.is-fullwidth {
  box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important;
}
.columns {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
// #sectionPageMain {
//   padding: 0.5rem 1.5rem !important;
//   padding-bottom: 3rem !important;
// }
// .card {
//   border-radius: 6px;
//   border: 1px solid var(--main-color-bg-3);
//   background: var(--main-color-bg);
//   height: unset !important;
// }
// h3.subtitle.is-spaced {
//   color: lightgray;
// }
.p-1 {
  padding: 1em;
  width: 100%;
}
.sidebar-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    /* min-height: 100vh; */
    .sidebar-layout {
        display: flex;
        flex-direction: row;
        min-height: 100%;
        min-height: calc(100vh - ( 3.25rem - 0rem ) );
    }
}
@media only screen and (max-width: 768px) {
  .p-1 {
    width: calc(100% - 80px) !important;
  }
}
</style>
